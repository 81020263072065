<template>
  <div id="main">
    <el-container >
      <el-header class="clearfix">
        <div class="top ">
          <img class="img-logo" src="../../images/logo.png" alt="">
          <div class="company-info">中标院资源与环境标准化研发基地（湖南）</div>
<!--          <div class="phone"><i class="el-icon-phone"></i>服务热线：18007485818</div>-->
          <el-input class="search" placeholder="请输入内容" v-model="searchInput">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
          <!--          导航栏-->
          <el-menu router :default-active="activePath" class="menu" mode="horizontal" @select="handleSelect" >
            <el-menu-item index="/">首页</el-menu-item>


              <el-menu-item index="/BaseIntroduction">基地介绍</el-menu-item>
             <el-menu-item index="/CooperativePartner">战略合作</el-menu-item>
            <el-menu-item index="/base/baseDynamics">基地动态</el-menu-item>


<!--            <el-submenu index="2">-->
<!--              <template slot="title">基地动态</template>-->
<!--              <el-menu-item index="/BaseIntroduction">基地介绍</el-menu-item>-->
<!--              <el-menu-item index="/AchievementsAndHonors">基地成果及荣誉</el-menu-item>-->
<!--              <el-menu-item index="/CooperativePartner">合作伙伴</el-menu-item>-->
<!--            </el-submenu>-->

<!--            <el-menu-item index="/base/baseDynamics">基地动态</el-menu-item>-->
<!--            <el-menu-item index="/servicesCase/servicesCase">服务案例</el-menu-item>-->
            <el-submenu index="5">
              <template slot="title">联系我们</template>
              <el-menu-item index="/ContactUs">联系我们</el-menu-item>
              <el-menu-item index="/OnlineMessage">在线留言</el-menu-item>
              <el-menu-item index="/recruitment/RecruitmentMain">在线招聘</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-header>

        <router-view />

      <div class="footer" style="background: #f2f2f2">
        <div class="contact">
          <div class="contact-main">
            <ul>
              <li>
                <div class="contact-main-head">
                  <h3>友情链接</h3>
                  <el-divider class="contact-main-line"></el-divider>
                </div>
                <div class="contact-main-body">
                  <p><a href="https://www.cnis.ac.cn/pcindex/">中国标准化研究院</a></p>
                  <p><a href="http://www.sac.gov.cn/">国家标准化管理委员会</a></p>
                  <p><a href="http://std.samr.gov.cn/">全国标准信息公共服务平台</a></p>
                  <p><a href="https://www.qybzlp.com/">企业标准领跑者管理信息平台</a></p>
                </div>
              </li>

              <li>
                <div class="contact-main-head">
                  <h3>联系我们</h3>
                  <el-divider class="contact-main-line"></el-divider>
                </div>
                <div class="contact-main-body contact-p">
                  <div class="contact-info"><span class="img"><i class="el-icon-phone-outline"/></span><span class="info">电话：18007485818</span>
                  </div>
                  <div class="contact-info"><span class="img"><i class="el-icon-location-information"/></span><span
                      class="info">地址：湖南省长沙市高新区中电软件园总部大楼1012</span></div>
                  <div class="contact-info"><span class="img"><i class="el-icon-message"/></span><span class="info">邮箱：380221803@qq.com</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-main-head">
                  <h3>关注我们</h3>
                  <el-divider class="contact-main-line"></el-divider>
                </div>
                <div class="contact-main-body">
                  <div class="contact-QR"><img class="QR" src="../../images/二维码.jpg" alt="">
                    <div class="info">微信扫一扫 <br> 关注我们最新消息</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="footer-info">
            湖南楚熵信息科技有限公司 版权所有 | 域名备案信息： <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备20009551号-1</a>
          </div>
        </div>
      </div>
    </el-container>
  </div>

</template>

<script >
export default {
  name: "HomePage",
  data() {
    return {
      activePath: '',
      searchInput:''
    }
  },
  methods: {
    search: async function () {
      let {data} = await this.$http.get("/news/selectAfterNews/8");
    },
    handleSelect(path){
      window.sessionStorage.setItem('activePath',path)
    }
  },
  mounted() {
    this.activePath=window.sessionStorage.getItem("activePath")
  }
}
</script>

<style scoped>
a {
  color: #959595;
}

a:hover {
  color: #E6A23C;
}

.el-container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-header {
  position: fixed;
  width: 100%;
  min-width: 1200px;
  box-shadow: 0 0 2px #000;
  height: 110px !important;
  z-index: 99;
  background-color: #fff;
}

.el-main {
  width: 1200px;
  margin: 110px auto 0 auto;
  padding: 0;
}

.el-input {
  width: 250px;
}

.top {
  position: relative;
  width: 1200px;
  margin: 10px auto 0 auto;
  height: 110px;

}

.img-logo {
  margin-top: 5px;
  width: 65px;
  height: 65px;
}

.company-info {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  vertical-align: bottom;
  color: #01B300;
}

.search {
  position: absolute;
  right: 0;
}

.phone {
  position: absolute;
  display: inline-block;
  height: 40px;
  right: 260px;
  vertical-align: middle;
}

.phone:before {
  content: '';
  display: inline-block;
  height: 40px;
  vertical-align: middle;
}

.menu {
  position: absolute;
  bottom: 1px;
  top: 40px;
  right: 0;
  height: 59px;
  width: 570px;
  font-size: 16px !important;
}

.el-menu-item, /deep/ .el-submenu__title {
  font-size: 16px !important;
}

.contact {
  min-width: 1200px;
  height: 305px;
  background-color: #F2F2F2;
}

.contact-main {
  width: 1200px;
  margin: 0 auto;

}

.contact-main li {
  display: inline-block;
  width: 400px;
  padding-top: 40px;
  vertical-align: top;

}

.contact-main-line {
  width: 1.5em;
  border: 1px #E6A23C solid;
  border-radius: 5px;
  margin: 20px 0;
}

.contact-main-body p {
  width: 250px;
  color: #959595;
  margin-bottom: 20px;
}

.contact-main-body a {
  color: #959595;
}

.contact-main-body a:hover {
  color: #E6A23C;
}

.contact-p {
  font-size: 14px;
}

.contact-info .img {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 25px;
  text-align: center;
  color: #E6A23C;
  vertical-align: middle;
}

.contact-info .info {
  display: inline-block;
  width: 300px;
  color: #959595;
  padding-left: 10px;
  vertical-align: middle;
}

.contact-QR .QR {
  width: 98px;
  height: 98px;
  margin-right: 10px;
}

.contact-QR .info {
  display: inline-block;
  line-height: 30px;
  vertical-align: middle;
  color: #959595;
}

.carousel-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  padding: 10px 20px 0 20px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer-info {
  color: #909399;
  margin: 10px auto;
  background: #f2f2f2;
  text-align: center;
}


</style>
