<template>
  <div id="main">
    <div class="content">
      <el-carousel height="450px" :interval="2000">
        <el-carousel-item v-for="item in rotationMaps">
          <img :src="item.url" alt=""/>
        </el-carousel-item>
      </el-carousel>

      <div class="model-4">
        <ul>
          <li>
            <a href="https://cnishn.com/EnergySystem">
              <div class="images"><img src="https://oss-sys.oss-cn-shanghai.aliyuncs.com/img/%E7%BB%BF%E5%BB%BA%E7%B3%BB%E7%BB%9F.jpg" alt=""/></div>
            </a>
          </li>

          <li>
            <a href="https://cnishn.com/msep">
              <div class="images">
                <!--                <div style="position: absolute; width: 100%;height: 100%; ;z-index: 99"></div>-->
                <img src="https://oss-sys.oss-cn-shanghai.aliyuncs.com/img/%E7%94%B5%E6%9C%BA%E7%B3%BB%E7%BB%9F.jpg" alt=""/>
              </div>
            </a>
          </li>
          <li>
            <a href="https://cnishn.com/shp">
              <div class="images"><img src="https://oss-sys.oss-cn-shanghai.aliyuncs.com/img/%E6%A0%87%E5%87%86%E5%8C%96%E6%89%98%E7%AE%A1.jpg" alt=""/></div>
            </a>
          </li>
          <li>
            <a href="https://cnishn.com/shp_c">
              <div class="images"><img src="https://oss-sys.oss-cn-shanghai.aliyuncs.com/img/%E4%BC%81%E4%B8%9A%E7%AB%AF.jpg" alt=""/></div>
            </a>
          </li>
        </ul>
      </div>
      <div class="server">
        <div>Main Business 主营业务</div>
        <el-divider><i class="el-icon-star-off"></i></el-divider>
        <div>专业化、私人化、定制化的标准化服务</div>
      </div>

      <div class="main-business">
        <ul style="text-align: center">
          <router-link to="/module/StandardizedHosting">
            <li>
              <div class="main-business-img"><img src="../../images/托管.png" alt=""></div>
              <div>标准制修订</div>
            </li>
          </router-link>

          <!--          <router-link to="/module/StandardizationConsultation">-->
          <!--            <li>-->
          <!--              <div class="main-business-img"><img src="../../images/在线沟通.png" alt=""></div>-->
          <!--              <div>标准及标准化咨询</div>-->
          <!--            </li>-->
          <!--          </router-link>-->

          <router-link to="/module/StandardizedTraining">
            <li>
              <div class="main-business-img"><img src="../../images/培训.png" alt=""></div>
              <div>标准化培训</div>
            </li>
          </router-link>
          <router-link to="/module/ServiceDevelopmentPlatform">
            <li>
              <div class="main-business-img"><img src="../../images/评估.png" alt=""></div>
              <div>"标准化+"综合服务平台开发</div>
            </li>
          </router-link>
          <router-link to="/module/Leader">
            <li>
              <div class="main-business-img"><img src="../../images/开发.png" alt=""></div>
              <div>企业标准 "领跑者"</div>
            </li>
          </router-link>
          <router-link to="/module/GreenEvaluation">
            <li>
              <div class="main-business-img"><img src="../../images/计划.png" alt=""></div>
              <div>绿色产品</div>
            </li>
          </router-link>
          <router-link to="/module/StandardizationConsultation">
            <li>
              <div class="main-business-img"><img src="../../images/评价.png" alt=""></div>
              <div>“双碳”标准化咨询</div>
            </li>
          </router-link>


        </ul>
      </div>

      <div class="news">
        <div class="news-label">
          <router-link to="/News/NewsInformation">NEWS 新闻动态</router-link>
        </div>
        <el-divider><i class="el-icon-star-off"></i></el-divider>
        <div>标准化资讯，动态一览把握机会</div>
      </div>
      <div class="news-card">
        <ul>
          <li v-for="item in newsList">
            <el-skeleton style="width: 240px" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item
                    variant="image"
                    class="news-img"
                />
                <div class="news-card-content">

                  <div class="news-date">
                    <el-skeleton-item variant="text" style="display:flex;width: 50%; margin-left: 0;"/>
                    <el-skeleton-item variant="text" style="display:flex;width: 70%; margin: 10px 0 0 0"/>
                  </div>
                  <div class="news-title">
                    <el-skeleton-item variant="h1" style="display:flex;width: 100%; margin: 10px 0 0 0"/>
                  </div>
                </div>
              </template>
              <template>
                <router-link :to="{ path:'/News/Content',query:{News:item}}">
                  <div class="news-img"><img :src="item.coverUrl" alt=""></div>
                  <div class="news-card-content">
                    <div class="news-date">
                      <div class="day">{{ new Date(item.creatTime).format("dd") }}</div>
                      <div class="year">{{ new Date(item.creatTime).format("yyyy-MM") }} <span>>>></span></div>
                    </div>
                    <div class="news-title">{{ item.title }}</div>
                  </div>
                </router-link>
              </template>
            </el-skeleton>

          </li>
        </ul>
      </div>

      <div class="news">
        <div class="news-label">
          <router-link to="/AchievementsAndHonors">成果荣誉</router-link>
        </div>
        <el-divider><i class="el-icon-star-off"></i></el-divider>
        <!--        <div>标准化资讯，动态一览把握机会</div>-->
      </div>

      <el-carousel :interval="2000" type="card" height="300px">
        <el-carousel-item v-for="item in CGRY_Maps">
          <img :src="item.url" alt="">
          <div class="carousel-title">{{ item.title }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>

</template>

<script>

export default {
  data() {
    return {
      newsList: [],
      loading: true,
      rotationMaps: '',
      CGRY_Maps: [],
    }
  },
  async created() {
    this.newsList = Array.from({length: 8}, (item, index) => index + 1)

  },
  async mounted() {
    await this.getRotationMaps()
    await this.getNews(8)
  },
  components: {},
  methods: {
    /**
     *
     *  获取新闻模块数据
     **/
    async getNews(num) {
      await this.$http.get("/news/selectAfterNews/" + num)
          .then(({data}) => {
            this.loading = false
            this.newsList = data
            console.log(data)
          })
          .catch((error) => {
            this.loading = true
          })
    },
    async getRotationMaps() {
      await this.$http.get('RotationMap/queryByLabel/主页').then(({data}) => {
        this.rotationMaps = data.obj

      }).catch((error) => {

      })
      await this.$http.get('RotationMap/queryByLabel/成果荣誉').then(({data}) => {
        this.CGRY_Maps = data.obj

      }).catch((error) => {

      })
    }
  }
}
</script>

<style scoped>
#main {
  width: 100%;
  margin-top: 10px;
}

a {
  color: #000;
}

a:hover {
  color: #E6A23C;
}

.content {
  width: 1200px;
  margin: 110px auto 0 auto;
}

.el-carousel {
  margin-top: 2px;
  border-radius: 5px;
}

.el-carousel__item img {
  width: 100%;
  height: 100%;
}

.model-4 ul{
  width: 100%;
  margin: 40px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.model-4 li {
  /*position: relative;*/
  /*display: inline-block;*/
  overflow: hidden;
  width: 250px;
  height: 160px;
  /*margin: 0 10px;*/
  border-radius: 5px;
  cursor: pointer;

}

.model-4 li:hover img {
  transform: scale(1.2);
}


.model-4 li .images img {
  width: 100%;
  transition: all 0.6s;
}

.model-4 li .label {
  margin-top: 10px;
  font-size: 20px;
}



.server {
  margin-top: 70px;
  height: 100px;
  text-align: center;
}

.news {
  margin-top: 20px;
  height: 100px;
  text-align: center;
}

.news-label {
  height: 25px;
  transition: all 0.6s;
}

.news-label:hover {
  transform: scale(1.1);
}

.server :nth-child(1), .news :nth-child(1) {
  font-size: 18px;
  color: #42b983;
}

.server :nth-child(3), .news :nth-child(3) {
  color: #99a9bf;
}

.server .el-divider, .news .el-divider {
  margin: 10px auto;
  width: 160px;
  background: #99a9bf;

}

.main-business li {
  display: inline-block;
  width: 190px;
  height: 170px;
  margin: auto 0;
  vertical-align: middle;
  text-align: center;
}

.main-business li:hover {
  color: #E6A23C;
}

.main-business-img {
  width: 98px;
  height: 98px;
  padding: 15px;
  border-radius: 5px;
  margin: 0 auto 10px auto;
  border: 1px rgba(149, 149, 149, 0.3) solid;

}

.main-business-img:hover {
  border: 1px #E6A23C solid;
}

.main-business-img img {
  width: 100%;
  height: 100%;

}

.news-card {
  text-align: center;
}

.news-card li {
  display: inline-block;
  width: 250px;
  height: 280px;
  margin: 10px 15px;
  vertical-align: top;
  text-align: left;
}

.news-img {
  width: 250px;
  height: 155px;
  border: 1px rgba(149, 149, 149, 0.3) solid;
  border-bottom: 0;
  border-radius: 5px 5px 0 0
}

.news-card-content {
  width: 250px;
  height: 125px;
  border: 1px rgba(149, 149, 149, 0.3) solid;
  border-top: 0;
  border-radius: 5px;
  padding: 5px;
}

.news-date {
  width: 215px;
  height: 50px;
  margin: 0 auto;
  padding: 2px;
  border-bottom: 1px #959595 solid;

}

.news-title {
  width: 215px;
  height: 60px;
  margin: 0 auto;
}

.news-card li .day {
  font-size: 18px;
  text-align: left;
  color: #959595;
  padding-left: 0.5em;
}

.news-card li .year {
  position: relative;
  text-align: left;
  font-size: 12px;
  color: #959595;
}

.news-card li .year span {
  display: inline-block;
  position: absolute;
  right: 0;
  color: #42b983;
  font-size: 14px;
}

.news-img img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.6s;
}

.news-img {
  overflow: hidden;
}

.news-img img:hover {
  transform: scale(1.4);
}

.contact {
  height: 305px;
  background-color: #F2F2F2;
  margin-top: 10px;
}

.contact-main {
  width: 1200px;
  margin: 0 auto;

}

.contact-main li {
  display: inline-block;
  width: 400px;
  padding-top: 40px;
  vertical-align: top;

}

.contact-main-line {
  width: 1.5em;
  border: 1px #E6A23C solid;
  border-radius: 5px;
  margin: 20px 0;
}

.contact-main-body p {
  width: 250px;
  color: #959595;
  margin-bottom: 20px;
}

.contact-main-body a {
  color: #959595;
}

.contact-main-body a:hover {
  color: #E6A23C;
}

.contact-p {
  font-size: 14px;
}

.contact-info .img {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 25px;
  text-align: center;
  color: #E6A23C;
  vertical-align: middle;
}

.contact-info .info {
  display: inline-block;
  width: 300px;
  color: #959595;
  padding-left: 10px;
  vertical-align: middle;
}

.contact-QR .QR {
  width: 98px;
  height: 98px;
  margin-right: 10px;
}

.contact-QR .info {
  display: inline-block;
  line-height: 30px;
  vertical-align: middle;
  color: #959595;
}

.carousel-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  padding: 10px 20px 0 20px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer-info {
  color: #909399;
  margin: 10px auto;
  background: #f2f2f2;
  text-align: center;
}

</style>
